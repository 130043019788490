module.exports = {
  // You can put the post mock up data here. Ex:
  // ---
  // 'ruangcontoh/user/login': {
  //   status: 'success',
  //   data: {
  //     token: 'companytoken',
  //     refreshToken: 'companyrefreshtoken',
  //   },
  // },
};
