// @flow

module.exports = {
  // You can put get mock data here. Example:
  // ---
  // 'ruangcontoh/user/viewerContext/': {
  //   status: 'success',
  //   data: {
  //     deviceId: 'None',
  //     deviceName: 'None',
  //     userSerial: 'company1',
  //     token: 'companytoken',
  //     refreshToken: 'companyrefreshtoken',
  //     isLoggedIn: 'true',
  //   },
  // },
};
